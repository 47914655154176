<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>{{ $t(rightsGroup.title) }}</b-card-title>
    </b-card-body>
    <b-table
      :items="rightsGroup.subgroups"
      striped
      responsive
      class="mb-0"
    >
      <template #cell(title)="data">
        {{ $t(data.item.title) }}
      </template>
      <template #cell()="item">
        <b-form-checkbox
          v-if="item.value"
          v-model="item.value.value"
          :disabled="viewMode"
        />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BFormCheckbox,
  },
  props: {
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    viewMode: {
      type: Boolean,
      required: false,
      default: true,
    },
    rightsGroup: {
      type: Object,
      required: false,
      default: () => { },
    },
  },
}
</script>
