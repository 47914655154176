<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :extra-actions="extraActions"
    :submit-callback="submitCallback"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <b-tab active>
          <template #title>
            <feather-icon icon="UsersIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="formFields"
            allow-autofocus
          />                    
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard, BFormCheckbox } from 'bootstrap-vue'
import { translationUtils } from '@core/mixins/ui/translations'
import { formUtils } from '@core/mixins/ui/forms'
import {
  getUserRights,
  isAdmin,
  isSeller,
  isSupplier,
  canEditPrice,
  canPublishToShop,
  canUnPublishFromShop,
} from '@/auth/utils'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import UserPermissionsCard from '../../users/UserPermissionsCard.vue'

import storeModule from '../dealSubscriptionsStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
    BFormCheckbox,
    UserPermissionsCard,
  },
  mixins: [translationUtils, formUtils],
  data() {
    return {
      entity: {
        rights: [],
      },
      entityLoaded: false,
      rightsLoaded: false,
      grantAllRights: false,
      grantGroupRights: [],
      roleOptions: [],
    }
  },
  computed: {
    canPublish() {
      const result = canPublishToShop();
      return result;
    },
    rights() {
      return getUserRights()
    },
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
    canEditPrice() {
      return canEditPrice()
    },
    config() {
      return {
        store: storeModule,
        module: 'user-groups',
        endpoint: 'user-groups',
        route: 'deal-subscriptions',
        title: {
          single: this.$t('Deal Subscription'),
          plural: this.$t('Deal Subscriptions'),
        },
      }
    },

    formFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
          rules: 'min:3',
          colSize: 4,
        },
        {
          id: 'subtitle',
          type: 'text',
          label: this.$t('subtitle'),
          rules: 'min:3',
          colSize: 4,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('Enabled'),
          colSize: 1,
        },
        {
          id: 'subscription',
          type: 'checkbox',
          label: this.$t('Subscription'),
          colSize: 1,
        },
        {
          id: 'monthlySubscription',
          type: 'text',
          label: this.$t('Monthly Subscription'),
          dependency: 'subscription',
          required: true,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'yearlySubscription',
          type: 'text',
          label: this.$t('Yearly Subscription'),
          dependency: 'subscription',
          required: true,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'maxProducts',
          type: 'number',
          label: this.$t('Maximum Products'),
          dependency: 'subscription',
          colSize: 3,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('Description'),
          required: true,
          colSize: 12,
        },
      ]
    },
    extraActions() {
      const canPublish = canPublishToShop()
      const canUnPublish = canUnPublishFromShop(this.entity.supplier)
      return [
        {
          endpoint: 'publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to e-shop',
          route: 'deal-subscriptions',
          actionValue: 'true',
          renderCondition: {
            key: 'published',
            value: false,
          },
          authorityValidation: canPublish,
        },
        {
          // callback: 'updateEshop',
          endpoint: 'publish',
          icon: 'UploadCloudIcon',
          title: 'Update e-shop',
          route: 'deal-subscriptions',
          actionValue: 'true',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canPublish,
        },
        {
          endpoint: 'publish',
          icon: 'DownloadCloudIcon',
          title: 'Remove from e-shop',
          route: 'deal-subscriptions',
          actionValue: 'false',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canUnPublish,
        },
      ]
    },
  },
  methods: { 
    submitCallback() {
      // this.$router.push({ name: 'deal-subscriptions-list' })
    },
  },
}
</script>
